import { Add, Clear } from "@mui/icons-material";
import { Card, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Tooltip, Typography, createFilterOptions, useAutocomplete } from "@mui/material";
import React, { useRef, useState } from "react";
import { FixedSizeList } from "react-window";

function SearchCategorySelect(props) {
  const { name, title, placeholder, options, entries, setEntries, validation, errTooltip, clearOnSelect } = props;

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [highlighted, setHighlighted] = useState();

  const inputRef = useRef('');

  // base function for filtering options
  const defaultFilterOptions = createFilterOptions();

  const displayNames = (options ?? []).reduce((names, node) => {
    names[node.id] = node.name;
    return names;
  }, {});

  // appends the given entry to the list of entries
  const addEntry = (newEntry) => {
    setEntries([...entries, newEntry]);
    if (clearOnSelect) setQuery('');
  }

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: name,
    options: options ?? [],
    disableCloseOnSelect: true,
    autoHighlight: true,
    open,
    freeSolo: true,
    inputValue: query,
    getOptionLabel: option => displayNames[option?.id] ?? option.id ?? option,
    filterOptions: (options, state) => {
      // remove options that are already selected
      const allResults = defaultFilterOptions(options, state)
        .filter(result => entries.every(entry => entry !== result.id));

      if (allResults.length === 0)
        return [{ disabled: true, id: 'no results' }];
      return allResults;
    },
    onOpen: () => setOpen(true),
    onChange: (_, selected) => {
      if (options) {
        // autocomplete case
        if (!selected || selected.disabled) return;
        addEntry(selected.id);
      }
      else {
        // non-autocomplete case
        if (query === '') return;
        if (validation && !validation(query)) return;
        if (entries.some(entry => query === entry)) return;
        addEntry(query);
      }
    },
    onHighlightChange: (_, option) => setHighlighted(option)
  });

  const Row = ({ index, style }) => {
    const option = groupedOptions[index]
    const title = displayNames[option.id] ?? option.id;
    const pos = title.toLowerCase().indexOf(query.toLowerCase());

    return <ListItem
      {...getOptionProps({ option, index })}
      style={style}
      sx={{
        backgroundColor: highlighted === option ? 'lightgray' : 'transparent',
        cursor: 'pointer',
        padding: '0.25rem 0.5rem',
        whiteSpace: 'pre',
      }}
      onClick={() => {
        if (option.disabled) return;
        // add the option to entries
        addEntry(option.id);
      }}
    >
      {/* bold the substring that matches user input */}
      {option.disabled
        ? title
        : <>
          {title.slice(0, pos)}
          <b>{title.slice(pos, pos + query.length)}</b>
          {title.slice(pos + query.length)}
        </>}

    </ListItem>
  };

  return <Card sx={{
    padding: 1,
    overflow: 'visible'
  }}>
    <Typography sx={{ borderBottom: 'solid dimgray 1px' }}>{title}</Typography>
    <List dense sx={{
      height: '20vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* List of entries */}
      <List dense sx={{
        overflowY: 'auto',
        padding: '2px 0',
        scrollbarGutter: 'stable',
      }}>
        {
          entries?.map((entry, index) =>
            <ListItem key={entry} disablePadding secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => {
                // remove the entry that was clicked
                setEntries([...entries.slice(0, index), ...entries.slice(index + 1)]);
              }}>
                <Clear />
              </IconButton>
            }>
              {/* this padding aligns entry text to the searchbar's text */}
              <ListItemText sx={{ marginLeft: '24px', padding: '0.25rem 0.5rem' }} primary={displayNames[entry] ?? entry} />
            </ListItem>
          )
        }
      </List>

      {/* Search bar for adding new entries */}
      <ListItem
        disablePadding
        sx={{ paddingRight: '24px' }}
        {...getRootProps()}
        onClick={() => {
          setOpen(true);
          inputRef.current.focus();
        }}
        onBlur={() => {
          setOpen(false)

          // try to add the typed word if this is not a select with options
          if (options) return;
          if (query === '') return;
          if (validation && !validation(query)) return;
          if (entries.some(entry => query === entry)) return;
          addEntry(query);
        }}
      >
        <ListItemIcon
          sx={{ minWidth: 0, margin: '0 -8px' }}
          onClick={() => {
            if (!open) return;
            if (options) {
              // autocomplete case
              if (highlighted)
                addEntry(highlighted.id);
            }
            else {
              // non-autocomplete case
              if (query === '') return;
              if (validation && !validation(query)) return;
              if (entries.some(entry => query === entry)) return;
              addEntry(query);
            }
          }}
        >
          <IconButton>
            <Add />
          </IconButton>
        </ListItemIcon>
        {
          !open && !query && <Typography variant="subtitle1" sx={{ padding: '0 0.5rem' }}>
            ...
          </Typography>
        }
        <div style={{ width: '100%', position: 'relative' }}>
          <Tooltip
            open={validation && !validation(query)}
            title={errTooltip}
            arrow
          >
            <TextField
              placeholder={placeholder}
              error={validation && !validation(query)}
              fullWidth
              size="small"
              sx={{
                opacity: (open || query) ? '100%' : '0',
                '& .MuiInputBase-input': { padding: '0.25rem 0.5rem' }
              }}
              inputRef={inputRef}
              inputProps={{ ...getInputProps(), sx: { color: open ? 'black' : 'gray' } }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Tooltip>

          {/* options dropdown */}
          {open && options && <Paper
            {...getListboxProps()}
            elevation={4}
            sx={{
              position: 'absolute',
              zIndex: 1,
              left: 0,
              right: 0,
              overflow: 'auto'
            }}
          >
            <FixedSizeList
              height={Math.min(groupedOptions.length * 40, 160)}
              width={'100%'}
              itemCount={groupedOptions.length}
              itemSize={40}
              overScanCount={30}
            >
              {Row}
            </FixedSizeList>
          </Paper>}
        </div>
      </ListItem>
    </List>
  </Card >;
}

export default SearchCategorySelect;