import React from "react";
import { Button } from "@mui/material";

export default function Results({ data }) {
  const results = data?.edges;
  const connectionInfo = data?.connectionInfo;

  let uniqueIds = [];

  /**
   * Turns 1 object into the bibtex
   * @param {Object} result
   * @returns String of bibtex
   */
  const getBibtexText = (result) => {
    const bibtex = result.bibtexFields;

    if (!bibtex) {
      return ["", ""];
    }

    // Booktitle field is only used for incollection and inproceedings
    if (bibtex.type === "INCOLLECTION" || bibtex.type === "INPROCEEDINGS") {
      // Handle booktitle field if necessary
    }

    if (bibtex["journal"]) {
      bibtex["journal"] = bibtex["journal"].replace("&", "{\\&}");
    }

    // Get access date for non-paper BibTeX entries
    let date = new Date();
    date =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    // Get current URL for non-paper BibTeX entries
    const url = result.doi
      ? result.doi
      : `https://catalog.caida.org/${result.id.split(":")[0]}/${
          result.id.split(":")[1]
        }`;

    // Create unique ID for each BibTeX entry
    const getUniqueId = (result) => {
      const shortname_list = result.id.split(":")[1].split("_").slice(1);
      const shortname = shortname_list.reduce(
        (prev, current) => prev + current[0],
        ""
      );
      const year = result.id.split(":")[1].split("_")[0];
      if (result["__typename"] === "Paper") {
        const author =
          result.authors && result.authors[0].person.nameLast.toLowerCase();
        let extra = result.name.split(" ")[0];

        if (result.publisher) {
          extra =
            result.publisher
              .split(" ")
              .reduce((acc, curr) => acc + curr[0], "") +
            result.name.split(" ").reduce((acc, curr) => acc + curr[0], "");
        } else {
          extra = result.name
            .split(" ")
            .reduce((acc, curr) => acc + curr[0], "");
        }

        return [`${year}-${author}-${shortname}`, extra];
      } else if (
        result["__typename"] === "Media" ||
        result["__typename"] === "Presentation"
      ) {
        const presenter =
          result.presenters && result.presenters[0].person.nameLast
            ? `-${result.presenters[0].person.nameLast.toLowerCase()}`
            : result.type
            ? `-${result.type.toLowerCase()}`
            : "";
        return [`${year}${presenter}-${shortname}`, result.id.split("_").pop()];
      } else if (
        result["__typename"] === "Software" ||
        result["__typename"] === "Dataset" ||
        result["__typename"] === "Recipe"
      ) {
        return [result.id.split(":")[1], result.id.split(":")[0]];
      }
      return [];
    };

    // Unique ID
    const [idCheck, extraTitle] = getUniqueId(result);
    const id = uniqueIds.includes(idCheck)
      ? `${idCheck}-${extraTitle}`
      : idCheck;

    uniqueIds.push(id);

    // BibTeX type
    const bibType = `@${
      bibtex ? `${bibtex.type.toLowerCase()}{${id},\n` : `misc{${id},\n`
    }`;

    // Authors
    let authors = "";
    if (result.authors) {
      const names = result.authors
        .map(
          ({ person: { nameLast, nameFirst } }) =>
            `${nameLast}${nameFirst ? `, ${nameFirst.trim().slice(0, 1)}` : ""}`
        )
        .join(" and ");
      authors = `  author = {${names}},\n`;
    } else if (result.presenters) {
      const names = result.presenters
        .map(
          ({ person: { nameLast, nameFirst } }) =>
            `${nameLast}${nameFirst ? `, ${nameFirst.trim().slice(0, 1)}` : ""}`
        )
        .join(" and ");
      authors = `  author = {${names}},\n`;
    }

    // Title
    const title = `  title = {{${result.name.replace("&", "{\\&}")}}},\n`;

    // Venue
    let venue = "";
    if (result.presenters && result.presenters[0].venue) {
      venue = `  venue = {${result.presenters[0].venue}},\n`;
    }

    // Date (Year and Month)
    const year = result.date.split("-")[0];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[parseInt(result.date.split("-")[1]) - 1];

    const dateFields = `  year = {${year}},\n  month = {${month}},\n`;

    // Detail string construction
    let detail = "";
    bibtex &&
      Object.keys(bibtex).forEach((key, index) => {
        if (key === "type") return;
        let value = bibtex[key];

        if (value && key === "bookTitle") {
          value = value.replace("&", "{\\&}");
        }
        if (value && key === "month") {
          value = months[parseInt(value) - 1];
        }

        if (!bibtex[key]) {
          if (
            key === "bookTitle" &&
            (bibtex.type === "INCOLLECTION" || bibtex.type === "INPROCEEDINGS")
          ) {
            value = result.publisher.replace("&", "{\\&}");
          }
        }

        if (value && value !== "0") {
          detail += `  ${key === "bookTitle" ? "booktitle" : key} = {${value}}${
            index === Object.keys(bibtex).length - 1 ? "" : ",\n"
          }`;
        }
      });

    const datesUsed =
      result.__typename === "Dataset" ? "Dates used: <date(s) used>. " : "";

    const howPublished = !bibtex
      ? `  howpublished = {\\url{${url}}},\n  note = {${datesUsed}Accessed: ${date}}`
      : "";

    const tags =
      !result.tags ||
      (result.tags.length === 1 && result.tags[0].name === "caida")
        ? "\n}"
        : ",\n  keywords = {";

    const doi = result.doi ? `,\n  doi = {${result.doi}}` : "";

    let stringifiedTags =
      !result.tags ||
      (result.tags.length === 1 && result.tags[0].name === "caida")
        ? tags
        : tags +
          result.tags
            .filter((x) => x.name !== "caida")
            .map((tag) => tag.name)
            .join("   ") +
          "}\n}";

    const bibObject =
      bibType +
      authors +
      title +
      venue +
      dateFields +
      detail +
      howPublished +
      doi +
      stringifiedTags;
    return [bibObject, id];
  };

  /**
   * Returns the Results as bibtex
   * @param {*} props
   * @returns
   */
  function returnResults(results) {
    // const {results} = props
    let text = "";

    const bibtex =
      results &&
      results.map((result) => {
        const [bibtexText, id] = getBibtexText(result.node);
        if (result.node.__typename === "Collection") {
          return <></>;
        }
        text += "\n" + bibtexText;
        return (
          <pre key={id} style={{ textAlign: "left" }}>
            {bibtexText}
          </pre>
        );
      });
    // const unique_set = new Set(uniqueIds)
    // console.log(uniqueIds.length, unique_set.length)
    //  console.log(text)
    return [bibtex, text];
  }

  function handleDownload() {
    const element = document.createElement("a");
    const file = new Blob([bibtexString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "bibtex.bib";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  if (!data) {
    return <></>;
  }

  const [bibtexFormatted, bibtexString] = returnResults(results);

  return (
    <div style={{ marginTop: "1rem" }}>
      <table style={{ width: "100%", borderStyle: "solid" }}>
        <thead>
          <tr>
            <th>totalCount</th>
            {connectionInfo &&
              Object.keys(connectionInfo?.totalCounts).map((type) => {
                return <th key={type}>{type}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.totalCount}</td>
            {connectionInfo &&
              Object.entries(connectionInfo?.totalCounts).map(
                ([key, value]) => {
                  return <td key={key}>{value}</td>;
                }
              )}
          </tr>
        </tbody>
      </table>
      <Button
        sx={{ width: "100%", mt: "1rem" }}
        onClick={handleDownload}
        variant="contained"
      >
        Download
      </Button>
      {bibtexFormatted}
    </div>
  );
}
