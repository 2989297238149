import React, { useEffect, useRef, useState } from "react";
import { TextField, Button, Paper, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { getOptions } from "./ApiUtil";
import { FixedSizeList } from "react-window";

const categorySuggestions = [
  "links=",
  "links=tag:",
  "links=person:",
  "dates=",
  "types=",
];

const types = [
  "dataset",
  "paper",
  "presentation",
  "media",
  "software",
  "recipe",
];

const catalogKeys = [
  "types=",
  "type=",
  "persons=",
  "person=",
  "ids=",
  "dates=",
  "access=",
  "links=",
  "fields=",
];

export default function SearchBar(props) {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm ?? "");
  const [tagOptions, setTagOptions] = useState([]);
  const [personOptions, setPersonOptions] = useState([]);

  const [highlightedIndex, setHighlightedIndex] = useState(0); // index of highlighted option
  const [cursorPos, setCursorPos] = useState(0); // index of cursor in input (manually updated)
  const [history, setHistory] = useState([]); // stack of previous states for redo
  const [left, setLeft] = useState(0); // position of sugggestion box
  const [focused, setFocused] = useState(false); // controls if suggestions are visible

  const inputRef = useRef();
  const overlaySpanRef = useRef(); // span containing all text overlay
  const pendingSpanRef = useRef(); // span containing pending input (portion being considered for suggestions)

  const MAX_DISPLAYED_OPTIONS = 10; // max number of options displayed in suggestion dropdown

  // get lists of persons and tags from API
  useEffect(() => {
    getOptions("tag").then((options) => {
      setTagOptions(options.map((option) => option.id.slice("tag:".length)));
    });
    getOptions("person").then((options) => {
      setPersonOptions(
        options.map((option) => option.id.slice("person:".length))
      );
    });
  }, []);

  /**
   * Handle typing into search bar
   * @param {Object} event
   */
  function handleUpdateSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  /**
   * Update the position of the suggestion dropdown
   */
  if (inputRef.current && pendingSpanRef.current) {
    const pendingLeft = pendingSpanRef.current.getBoundingClientRect().left;
    const inputLeft = inputRef.current.getBoundingClientRect().left;
    setTimeout(() => setLeft(pendingLeft - inputLeft), 0);
  }

  function onClickLink() {
    // setSearchTerm('')
  }

  /**
   * Save the current state of the input into the history for redo
   * @param {string} searchTerm - the current state of searchTerm
   * @param {string} cursorPos - the current cursor position
   */
  const pushHistory = ([searchTerm, cursorPos]) => {
    setHistory((prev) => [[searchTerm, cursorPos], ...prev]);
  };

  /**
   * Get the previous state in history and remove it
   * @returns the most recent state in the history
   */
  const popHistory = () => {
    // default state when history is empty
    if (!history[0]) return ["", 0];

    // if the latest two states have the same searchTerm, or the most recent one matches
    // the current search term, use the 2nd latest one instead
    if (history[0]?.[0] === history[1]?.[0] || history[0]?.[0] === searchTerm) {
      setHistory((prev) => prev.slice(2));
      return history[1];
    }

    setHistory((prev) => prev.slice(1));
    return history[0];
  };

  /**
   * Replace the pending text with the given suggestion
   * @param {string} suggestion
   */
  const acceptSuggestion = (suggestion) => {
    pushHistory([searchTerm, cursorPos]);

    const newSearchTerm =
      searchTerm.slice(0, pendingRange[0]) +
      suggestion +
      searchTerm.slice(pendingRange[1]);
    setSearchTerm(newSearchTerm);

    // move the input cursor to the end of the suggestion
    const newCursorPos = pendingRange[0] + suggestion.length;
    setTimeout(() => {
      inputRef.current?.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);

    pushHistory([newSearchTerm, newCursorPos]);
    updateCursorPos();
  };

  /**
   * The actual input's text is made invisible and the overlay span shows text in the
   * exact same position instead to provide better control over text display. This
   * code copies the styling from the input to the overlay span so that their text
   * ends up the same size and in the same place.
   */
  const inputStyle = inputRef.current
    ? window.getComputedStyle(inputRef.current)
    : [];
  for (const prop of inputStyle) {
    if (
      prop === "transform" ||
      prop === "color" ||
      prop === "-webkit-text-fill-color" ||
      prop === "white-space-collapse"
    )
      continue;
    overlaySpanRef.current.style[prop] = inputStyle[prop];
  }

  /**
   * Update the cursorPos state to keep it in sync with the input's cursor position.
   * This must be done manually since changing cursor position isn't an event.
   */
  const updateCursorPos = () => {
    setCursorPos(inputRef.current?.selectionStart ?? 0);
  };

  // calculate the word (text separated by ' ' or ',') that the cursor is in
  const cursorChar = searchTerm.charAt(cursorPos);
  // if the cursor is on ',' or ' ', consider it to be in the word before that
  const pos =
    cursorChar === " " || cursorChar === "," ? cursorPos - 1 : cursorPos;
  const wordStart = searchTerm.lastIndexOf(" ", pos) + 1;
  const word = searchTerm.slice(wordStart).split(" ,")[0];

  // the pendingRange is the indices of searchTerm that would be replaced by a suggestion
  let pendingRange = [wordStart, wordStart + word.length];
  let displayOptions;
  let editingDate = false;
  if (word.startsWith("dates=")) {
    editingDate = true;
    displayOptions = [];
    pendingRange[0] =
      wordStart + Math.max("dates=".length, word.lastIndexOf(",", pos) + 1);
    const pending = searchTerm.slice(pendingRange[0]).split(/ |,/)[0];
    pendingRange[1] = pendingRange[0] + pending.length;
  } else if (word.startsWith("links=")) {
    // pendingRange begins after 'links=' or the latest ',' and ends at the next ' ' or ','
    pendingRange[0] =
      wordStart + Math.max("links=".length, word.lastIndexOf(",", pos) + 1);
    const pending = searchTerm.slice(pendingRange[0]).split(/ |,/)[0];
    pendingRange[1] = pendingRange[0] + pending.length;

    if (pending.startsWith("tag:")) {
      // update pendingRange to where the actual tag name begins
      const query = pending.slice("tag:".length);
      pendingRange[0] += "tag:".length;

      // displayOptions will be the tags that start with the query, followed by the tags the query occurs anywhere in
      displayOptions = [
        ...tagOptions.filter((option) => {
          return option.startsWith(query) && option !== query;
        }),
        ...tagOptions.filter((option) => {
          return (
            !option.startsWith(query) &&
            option.includes(query) &&
            option !== pending
          );
        }),
      ];
    } else if (pending.startsWith("person:")) {
      // update pendingRange to where the actual person name begins
      const query = pending.slice("person:".length);
      pendingRange[0] += "person:".length;

      // displayOptions first has persons that start with the query, followed by the persons where query occurs anywhere
      displayOptions = [
        ...personOptions.filter((option) => {
          return option.startsWith(query) && option !== query;
        }),
        ...personOptions.filter((option) => {
          return (
            !option.startsWith(query) &&
            option.includes(query) &&
            option !== pending
          );
        }),
      ];
    } else {
      // case where person has typed 'links=' but not 'tag:' or 'person:' yet
      displayOptions = ["tag:", "person:"].filter((option) =>
        option.includes(pending)
      );
    }
  } else if (word.startsWith("types=")) {
    // pendingRange begins after 'types=' or the latest ',' and ends at the next ' ' or ','
    pendingRange[0] =
      wordStart + Math.max("types=".length, word.lastIndexOf(",", pos) + 1);
    const pending = searchTerm.slice(pendingRange[0]).split(/ |,/)[0];
    pendingRange[1] = pendingRange[0] + pending.length;
    displayOptions = types.filter(
      (option) => option.includes(pending) && option !== pending
    );
  } else {
    // suggestions for '<category>='
    displayOptions = categorySuggestions.filter(
      (option) => option.includes(word) && option !== word
    );
  }

  // reset highlighted index when it would be outside the suggestion range
  useEffect(() => {
    if (highlightedIndex >= displayOptions.length && highlightedIndex !== 0)
      setHighlightedIndex(0);
  }, [highlightedIndex, displayOptions]);

  /**
   * Function used by FixedSizedList to render list items.
   * `style` object contains position styles used by the virtualized list
   * @returns react component of the list item
   */
  const Row = ({ index, style }) => {
    const title = displayOptions[index];
    const match = searchTerm.slice(...pendingRange);
    const pos = title.indexOf(match);
    return (
      <ListItem
        style={style}
        sx={{
          backgroundColor:
            index === highlightedIndex ? "lightgray" : "transparent",
          cursor: "pointer",
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          acceptSuggestion(displayOptions[index]);
        }}
        onMouseMove={() => setHighlightedIndex(index)}
      >
        {/* bold the substring that matches user input */}
        {title.slice(0, pos)}
        <b>{match}</b>
        {title.slice(pos + match.length)}
      </ListItem>
    );
  };

  // regex recognizes dates of the form YYYY, YYYY-MM, or YYYY-MM-DD
  const dateRegex =
    /^((19|20)[0-9]{2}(-(0[1-9]|1[012])(-(0[1-9]|[12][0-9]|3[01])){0,1}){0,1}){0,1}$/;

  /**
   * Parse a string to an array of react elements with highlighting
   * @param {string} input
   * @returns array of spans with keywords highlighted
   */
  const parseToHighlighted = (input) => {
    // regex recognizes '<category>=' or words split by ',' and ' '
    const regex = /(!?[a-z]+=|[^ ,=]+)/g;

    const elems = []; // array of spans to be returned
    let match;
    let lastIndex = 0;
    let index = 0; // unique key index
    while ((match = regex.exec(input)) !== null) {
      // push a span of text between regex matches
      elems.push(
        <span key={index++}>{input.slice(lastIndex, match.index)}</span>
      );

      // generate style for the matched section, then push a span for it
      let style = {};
      if (match[0].includes("=")) {
        if (
          catalogKeys.includes(match[0]) ||
          (match[0][0] === "!" && catalogKeys.includes(match[0].slice(1)))
        )
          // highlight '<category>='
          style = { backgroundColor: "powderblue" };
        // underline unknown category names
        else style = { textDecoration: "red dotted underline" };
      } else if (
        match[0].startsWith("tag:") &&
        match[0] !== "tag:" &&
        !tagOptions.includes(match[0].slice("tag:".length))
      ) {
        // underline tags with no results
        style = { textDecoration: "goldenrod wavy underline" };
      } else if (
        match[0].startsWith("person:") &&
        match[0] !== "person:" &&
        !personOptions.includes(match[0].slice("person:".length))
      ) {
        // underline persons with no results
        style = { textDecoration: "goldenrod wavy underline" };
      } else if (
        searchTerm.slice(
          searchTerm.lastIndexOf(" ", match.index) + 1,
          searchTerm.lastIndexOf("=", match.index)
        ) === "dates" &&
        !dateRegex.test(match[0])
      ) {
        // underline invalid dates
        style = { textDecoration: "red wavy underline" };
      }

      elems.push(
        <span key={index++} style={style}>
          {match[0]}
        </span>
      );
      lastIndex = match.index + match[0].length;
    }

    // add the final text that wasn't matched by regex
    elems.push(<span key={index++}>{input.slice(lastIndex)}</span>);

    return elems;
  };

  /**
   * Handle keyDown inside the search bar
   * @param {KeyboardEvent} event
   */
  const handleKeyDown = (event) => {
    switch (event.code) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex((i) => (i + 1) % displayOptions.length || 1);
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex(
          (i) => (i - 1 + displayOptions.length) % (displayOptions.length || 1)
        );
        break;
      case "Tab":
        if (displayOptions.length)
          acceptSuggestion(displayOptions[highlightedIndex]);
        event.preventDefault();
        break;
      case "KeyZ":
        // handle undo
        event.preventDefault();
        if (event.ctrlKey) {
          const [newSearchTerm, newCursorPos] = popHistory();
          setSearchTerm(newSearchTerm);
          inputRef.current.selectionStart = newCursorPos;
          inputRef.current.selectionEnd = newCursorPos;
        }
        break;
      default:
      // should not reach
    }
  };

  // try filling in the rest of the date and see if the input could be the start of a date
  const matchesDateStart = (input) => {
    const defaultDate = input.slice(0, 1) === "1" ? "1900-01-01" : "2000-01-01";
    const string = input + defaultDate.slice(input.length);
    return dateRegex.test(string);
  };

  // calculate the suggestion (gray text shown after cursor)
  const pendingLength = pendingRange[1] - pendingRange[0];
  let suggestion;
  if (editingDate && matchesDateStart(searchTerm.slice(...pendingRange))) {
    // generate appropriate guide for date
    if (pendingLength < 4) suggestion = "YYYY".slice(pendingLength);
    else if (pendingLength < 7) suggestion = "-MM?".slice(pendingLength - 4);
    else if (pendingLength < 10) suggestion = "-DD?".slice(pendingLength - 7);
    else suggestion = "";
  } else {
    // set `suggestion` to the text from pending to the end of the highlighted option
    const matchPos = displayOptions?.[highlightedIndex]?.indexOf(
      searchTerm.slice(...pendingRange)
    );
    suggestion = displayOptions[highlightedIndex]?.slice(
      matchPos + pendingLength
    );
  }

  return (
    <div style={{ position: "relative" }} onKeyDown={handleKeyDown}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "1rem",
          position: "relative",
          whiteSpace: "nowrap",
        }}
      >
        <TextField
          fullWidth
          value={searchTerm}
          onChange={handleUpdateSearchTerm}
          label="Search"
          id="search"
          inputRef={inputRef}
          inputProps={{
            spellCheck: false,
            style: { zIndex: 1, color: "transparent", caretColor: "black" },
            onFocus: () => setFocused(true),
            onBlur: () => setFocused(false),
            onClick: updateCursorPos,
            onKeyDown: updateCursorPos,
            onKeyUp: updateCursorPos,
          }}
        />
        &nbsp;
        <Link to={`/search?query=${searchTerm}`} onClick={onClickLink}>
          <Button
            aria-label="get bibtex"
            variant="contained"
            size="small"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            Get Bibtex
          </Button>
        </Link>
        {/* overlay text */}
        <div style={{ position: "absolute", overflowX: "hidden" }}>
          <span
            ref={overlaySpanRef}
            style={{
              whiteSpace: "pre",
              color: "black",
              WebkitTextFillColor: "black",
              transform: `translateX(-${inputRef.current?.scrollLeft ?? 0}px)`,
            }}
          >
            <span ref={pendingSpanRef}>
              {parseToHighlighted(searchTerm.slice(0, pendingRange[1]))}
            </span>
            {focused && cursorPos === searchTerm.length && (
              <span style={{ color: "gray", WebkitTextFillColor: "gray" }}>
                {suggestion}
              </span>
            )}
            <span>{parseToHighlighted(searchTerm.slice(pendingRange[1]))}</span>
          </span>
        </div>
      </div>
      {/* suggestion box */}
      {focused && displayOptions.length > 0 && (
        <Paper
          sx={{
            position: "absolute",
            zIndex: 2,
            left,
            marginLeft: "-14px",
          }}
        >
          <FixedSizeList
            height={Math.min(
              displayOptions.length * 40,
              MAX_DISPLAYED_OPTIONS * 40
            )}
            width={300}
            itemCount={displayOptions.length}
            itemSize={40}
            overScanCount={30}
          >
            {Row}
          </FixedSizeList>
        </Paper>
      )}
    </div>
  );
}
