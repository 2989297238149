import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";
import Results from "./Results";
import Navbar from "./Navbar";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import SearchBar from "./SearchBar";

function DetailsPage() {
  const [results, setResults] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  // const [searchTerm, setSearchTerm] = ('')

  // change data based on URL parameters
  useEffect(() => {
    setLoading(true);
    // get data
    const baseURL = "https://api.catalog.caida.org/v1/";
    const query = getQuery(searchParams.get("query"));

    axios
      .post(baseURL, { query })
      .then((res) => {
        setResults(res.data.data.search);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [searchParams]);

  /**
   * Sets query string with the searchTerm
   * @returns String query
   */
  const getQuery = (searchTerm) => {
    const queryString = `{
    search(query: "${searchTerm}"){
        totalCount            
        connectionInfo {
           totalCounts {
              dataset
              software
              recipe
              paper
              media
              collection
              presentation
           }
        }
        edges {
            node {
                id
                name
                description
                doi
                __typename
                date
                access {
                  url
                  access
                }
                tags {
                    name
                    id
                }
                ... on Dataset {
                  dateStart
                  dateEnd
                  status
                  organization
                  schema
                  class_namespaces
                  tags {
                    name
                    id
                  }
                  links {
                    to {
                      __typename
                      name
                      id
                    }
                  }
                  resources {
                    name
                    url
                  }
                }
                 ... on Software {
                  dateCreated
                  dateModified
                  status
                  resources {
                    name
                    url
                  } 
                }
                ... on Paper {
                  annotation
                  datePublished 
                  authors {
                    person {
                      nameFirst
                      nameLast
                    }
                  }
                  bibtexFields {
                    type
                    bookTitle
                    institution
                    journal
                    volume
                    number
                    pages
                    peerReviewedYes
                    bibtex
                    year
                    month
                  }
                  publisher
                }
                ... on Recipe {
                  authors {
                    person {
                        nameFirst
                        nameLast
                    }
                  }
                  resources {
                    name
                    url
                  }
                }
                ... on Media {
                  type
                  resources {
                    name
                    url
                  }
                  presenters {
                    person {
                      nameFirst
                      nameLast
                    }
                    organizations
                    venue
                    url
                  }
                  authors {
                    person {
                      nameFirst
                      nameLast
                    }
                    organizations
                  }
                }
                ... on Presentation {
                    resources {
                      name
                      url
                    }
                    presenters {
                      person {
                        nameFirst
                        nameLast
                      }
                      organizations
                      venue
                      url
                    }
                    authors {
                      person {
                        nameFirst
                        nameLast
                      }
                      organizations
                    }
                  }
                ...on Collection {
                    members {
                        id
                        name
                        __typename
                    } 
                }
            }
        }                   
    }
    }`;
    return queryString;
  };

  return (
    <div className="App">
      <Navbar />
      <Container>
        <SearchBar searchTerm={searchParams.get("query")} />
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography>
              <a
                href={`https://catalog.caida.org/search?query=${searchParams.get(
                  "query"
                )}`}
              >
                {`https://catalog.caida.org/search?query=${searchParams.get(
                  "query"
                )}`}
              </a>
            </Typography>
            <Results data={results} />
          </>
        )}
      </Container>
    </div>
  );
}

export default DetailsPage;
