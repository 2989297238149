import './App.css';
import AdvancedSearchPage from './js/AdvancedSearchPage';
import DetailsPage from  './js/DetailsPage';
import HomePage from  './js/HomePage';
import React from 'react';

import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

function App() {

  return (
    <BrowserRouter>  
      <Routes>
        <Route path="/:route" element={<DetailsPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="advanced-search" element={<AdvancedSearchPage />} />
      </Routes>
    </BrowserRouter>
  );
} export default App;
