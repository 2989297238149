import React from 'react';

import { Container, Card, Grid, CardContent, Typography, CardActionArea } from '@mui/material';
import SearchBar from './SearchBar';
import Navbar from './Navbar';
import { Link } from "react-router-dom";

function HomePage() {    
  const presetCards = [
    {
      query: 'types=paper links=tag:caida',
      title: 'All Caida Papers'
    },
    {
      query: 'types=paper',
      title: 'All Papers'
    },
    {
      query: 'types=paper links=tag:caida,tag:funding_dibbs_panda',
      title: 'All Papers funded by dibbs-panda'
    },
  ]
  return (
    <div className="App">
      <Navbar />
      <Container>
        <SearchBar />
        <br />
        <Grid container spacing={{ xs: 1, md: 2, lg: 3}}>
          {
            presetCards.map((card) => {
              return <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card variant='outlined'>
               <CardActionArea component={Link} to={`search?query=${card.query}`}>
                <CardContent>
                  <Typography variant="button">{card.title}</Typography>
                </CardContent>
                </CardActionArea>
              </Card>
              </Grid>
            })
          }
        </Grid>
      </Container>
    </div>
  );
}
export default HomePage;
