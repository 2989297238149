import React, { useEffect, useState } from 'react';

import { Box, Button, Container, Typography } from '@mui/material';
import Navbar from './Navbar';
import SearchCategorySelect from './SearchCategorySelect';
import { getOptions } from './ApiUtil';
import { Link, useSearchParams } from 'react-router-dom';

const typeOptions = [
  { id: 'dataset', name: 'Dataset' },
  { id: 'presentation', name: 'Presentation' },
  { id: 'paper', name: 'Paper' },
  { id: 'recipe', name: 'Recipe' },
  { id: 'software', name: 'Software' },
  { id: 'media', name: 'Media' },
  { id: 'collection', name: 'Collection' }
]

function AdvancedSearchPage() {
  const [tagOptions, setTagOptions] = useState(null);
  const [personOptions, setPersonOptions] = useState(null);
  const [entries, setEntries] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const dateValidation = (input) => {
    // recognizes YYYY or YYYY-MM or YYYY-MM-DD
    return /^((19|20)[0-9]{2}(-(0[1-9]|1[012])(-(0[1-9]|[12][0-9]|3[01])){0,1}){0,1}){0,1}$/.test(input);
  }

  // arrays of categories are mapped to SearchCategorySelects
  // { name, title, placeholder, options?, validation?, errTooltip?, clearOnSelect? } 
  const searchCategories = [
    {
      name: 'keywords',
      title: 'Key words',
      placeholder: 'keyword',
      clearOnSelect: true
    },
    {
      name: 'tag',
      title: 'Tag',
      placeholder: 'tag',
      options: tagOptions,
    },
    {
      name: 'person',
      title: 'Person',
      placeholder: 'Last, First',
      options: personOptions,
    },
    {
      name: 'date',
      title: 'Date',
      placeholder: 'YYYY or YYYY-MM',
      validation: dateValidation,
      errTooltip: 'Enter a valid date in YYYY or YYYY-MM format.',
      clearOnSelect: true,
    },
    {
      name: 'type',
      title: 'Type',
      placeholder: 'type',
      options: typeOptions,
      clearOnSelect: true,
    }
  ];

  useEffect(() => {
    // update entries from URL on initial load
    const newEntries = {}
    searchCategories.forEach(({ name }) => {
      newEntries[name] = searchParams.getAll(name);
    });
    setEntries(newEntries);

    getOptions('tag').then(options => {
      setTagOptions(options);
    });
    getOptions('person').then(options => {
      setPersonOptions(options);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update search params when `entries` changes
  useEffect(() => {
    const params = new URLSearchParams();
    searchCategories.forEach(({ name }) => {
      if (!entries[name]) return;
      entries[name].forEach(entry => {
        params.append(name, entry);
      });
    });
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);

  // generate query string
  const querySections = [];
  if (entries.keywords?.length > 0)
    querySections.push(entries.keywords.join(' '));
  if (entries.tag?.length > 0 || entries.person?.length > 0)
    querySections.push(`links=${[...(entries.tag ?? []), ...(entries.person ?? [])].join(',')}`);
  if (entries.date?.length > 0)
    querySections.push(`dates=${entries.date.join(',')}`);
  if (entries.type?.length > 0)
    querySections.push(`types=${entries.type.join(',')}`);
  const queryString = querySections.join(' ');

  return (
    <div className="AdvancedSearch">
      <Navbar />
      <Container sx={{ display: 'flex', flexDirection: 'column', gap: '3rem' }}>
        <Typography variant="h4">Advanced Search</Typography>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '2rem',
        }}>
          {searchCategories.map(({ name, title, placeholder, options, validation, errTooltip, clearOnSelect }) => {
            return <SearchCategorySelect
              key={name}
              name={name}
              title={title}
              placeholder={placeholder}
              options={options}
              entries={entries[name] ?? []}
              validation={validation}
              errTooltip={errTooltip}
              clearOnSelect={clearOnSelect}
              setEntries={entries => {
                setEntries(prev => ({ ...prev, [name]: entries }))
              }}
            />
          })}
        </Box>
        <Button variant="contained" component={Link} to={`/search?query=${queryString}`}>Generate</Button>
      </Container>
    </div>
  );
}

export default AdvancedSearchPage;
