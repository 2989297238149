import axios from 'axios';

const baseURL = 'https://api.catalog.caida.org/v1/';

export const getOptions = async (optionType) => {
  const query = ` {
    search(query: "type=${optionType}") {
      edges {
        node {
          id
          name
        }
      }
    }
  }`

  try {
    const res = await axios.post(baseURL, { query });
    return res.data.data.search.edges.map(edge => edge.node);
  } catch {
    console.log('axios error during getOptions');
  }
};