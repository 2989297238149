import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import styled from 'styled-components';

const WhiteLink = styled('a')(() => ({
    color: 'white',
    textDecoration: 'none'
}));

export default function Navbar() {
    return <Box sx={{mb: '1rem'}}>
        <AppBar position="static" sx={{ flexGrow: 1 ,  }}>
            <Toolbar sx={{justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <WhiteLink href="/">Home</WhiteLink>
                    <WhiteLink href="/advanced-search">Advanced Search</WhiteLink>
                </Box>

                <WhiteLink href="https://catalog.caida.org/">Catalog</WhiteLink>
            </Toolbar>
        </AppBar>
    </Box>
}
